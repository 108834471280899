export const programsData = [
  {
     image: "https://clipground.com/images/dumbbell-vector-clipart-1.png",
        width: 16 , // Width in pixels
        height: 15, // Width in pixels  
    heading: "Strength Training",
    details:
      "In this program, you are trained to improve your strength through many exercises.",
  },
  {
    image: "https://www.pngall.com/wp-content/uploads/12/Running-Silhouette-PNG-Free-Image.png",
    width: 16,
    height:17,
    heading: "Cardio Training",
    details:
      "In this program, you are trained to do sequential moves in range of 20 until 30 minutes.",
  },
  {
    image: "https://images.vexels.com/media/users/3/145652/isolated/preview/0ebb5dc04dc1d2658590b041e17eccfe-fire-flame-vector-by-vexels.png",
    width: 12,
    height:16,
    heading: "Fat Burning",
    details:
      "This program is suitable for you who wants to get rid of your fat and lose their weight.",
  },
  {
    image: "https://www.rvtc.org/images/programs/icons_programs_2020_health-sciences-200.png",
    width: 20,
    height:16,
    heading: "Health Fitness",
    details:
      "This programs is designed for those who exercises only for their body fitness not body building.",
  },
];



