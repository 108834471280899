import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_89qjgcs', 'template_plxxmfw', form.current, {
        publicKey: 'gDYWdBR4mQt8zADwC',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <>
    <div className="Join" id="join-us">
    <div className="left-j">
      <hr />
    <div>
        <span className='stroke-text'>READY TO </span>
        <span>LEVEL UP</span>
        </div>
      <div>
        <span>YOUR BODY </span>
        <span className='stroke-text'>WITH US?</span>
        </div>
    </div>
    </div>
    <div className="right-j">
     <form action="" className='email-container' onSubmit={sendEmail} ref={form}>
      <input type="email" name="user_email" placeholder='Enter your email address' />
      <button className='btn btn-j'>Join now</button>
     </form>
    </div>
    </>
  )
}

export default Join
