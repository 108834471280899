import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png'
import { Link } from 'react-router-dom';


const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    // Close the menu after scrolling
    setMenuOpened(false)
  };

  

  const mobile = window.innerWidth <= 768 ? true:false;
  const[menuOpened, setMenuOpened]=useState(false)
  return (
    <div className="header">
        <img src={Logo} alt="" className='logo'/>
        {(menuOpened===false && mobile == true)?(
          <div style={{
            backgroundColor:'var(--appColor)', 
            padding:'0.56rem',
            borderRadius:'5px' }}
            onClick={()=>setMenuOpened(true)}>
          <img src={Bars} alt="" style={{width:'1.5rem', height:'1.5rem'}}/></div>
        ):
        <ul className='header-menu'>
        <li>
          <button onClick={() => {scrollToSection('home') }}>Home</button>
        </li>
        <li>
          <button onClick={() => {scrollToSection('programs')}} style={{backgroundColor:"transparent", border:"none"}}>Programs</button>
        </li>
        <li>
          <button onClick={() => {scrollToSection('reasons')}}>Why Us</button>
        </li>
        <li>
          <button onClick={() => {scrollToSection('plans')}}>Plans</button>
        </li>
        <li>
          <button onClick={() => {scrollToSection('testimonials')}}>Testimonials</button>
        </li>
      </ul>
        }
        
    </div>
  )
}

export default Header
